import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Form, Field } from 'react-final-form';
import isValidEmail from 'validator/lib/isEmail';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Input from '../components/Input';
import Textarea from '../components/Input/Textarea';

const WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/183179/euzmop/';

const _sendGrooveSupport = values => {
  return fetch(`${WEBHOOK_URL}?${qs.stringify(values)}`)
    .then(res => res.json())
    .then(data => data);
};

const required = value => (value ? undefined : 'Required');

const isEmail = value =>
  isValidEmail(value || '') ? undefined : 'Please enter a valid email';

class SupportPage extends React.Component {
  static propTypes = {
    sendGrooveSupport: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sendGrooveSupport: _sendGrooveSupport,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userMessage: null,
    };
  }

  async handleSubmit(values) {
    const { sendGrooveSupport } = this.props;
    this.setState({ isLoading: true });

    try {
      await sendGrooveSupport(values);
      this.setState({
        userMessage: "Support request sent. We'll be in touch shortly.",
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        userMessage: 'Uh oh, something went wrong. Please try again.',
        isLoading: false,
      });
      throw new Error(e.message);
    }
  }

  renderForm() {
    const { isLoading } = this.state;

    return (
      <Form
        onSubmit={v => this.handleSubmit(v)}
        // initialValues={{
        //   email: get(user, 'data.email'),
        // }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Name"
                  error={meta.error}
                  touched={meta.touched}
                />
              )}
            </Field>
            <Field name="email" validate={isEmail}>
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Email"
                  error={meta.error}
                  touched={meta.touched}
                />
              )}
            </Field>
            <Field name="subject" validate={required}>
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Subject"
                  error={meta.error}
                  touched={meta.touched}
                />
              )}
            </Field>
            <Field name="message" validate={required}>
              {({ input, meta }) => (
                <Textarea
                  {...input}
                  className="h-64"
                  label="Message"
                  error={meta.error}
                  touched={meta.touched}
                />
              )}
            </Field>
            <button
              className="text-sm uppercase tracking-tight text-white bg-green rounded px-4 py-4"
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send Support Request'}
            </button>
          </form>
        )}
      />
    );
  }

  render() {
    const { userMessage } = this.state;

    return (
      <Layout pageTitle="Support">
        <Header type="dark" />

        <div className="rounded mt-12 shadow-lg w-full md:w-2/3 mx-auto bg-white">
          <div className="p-4 md:p-8 leading-normal text-grey-darkest">
            <h1 className="text-2xl mb-4">Customer Support</h1>
            <p className="text-grey-dark leading-normal mb-8">
              Need help with a site feature, billing or anything? Shoot us a
              message via the form below. We'll do our best to get back to you
              ASAP. You can also send an email to{' '}
              <a href="mailto:support@themakersmob.com">
                support@themakersmob.com
              </a>
            </p>

            {userMessage && (
              <div className="border rounded border-green border-2 mb-6 text-grey-darker w-full p-4">
                {userMessage}
              </div>
            )}
            {this.renderForm()}
          </div>
        </div>
      </Layout>
    );
  }
}

export default SupportPage;
