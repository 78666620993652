import React from 'react';
import PropTypes from 'prop-types';

const withField = WrappedComponent => {
  return class EnhancedComponent extends React.Component {
    render() {
      const { label, error, touched, name, ...rest } = this.props;
      const showError = error && touched;
      return (
        <div className="mb-4">
          <label
            className="block text-grey-darker text-sm font-bold mb-2 uppercase antialiased"
            htmlFor={name}
          >
            {label}
          </label>
          <div className="mb-2">
            <WrappedComponent {...rest} name={name} hasError={showError} />
          </div>
          {showError && <div className="text-red text-xs italic">{error}</div>}
        </div>
      );
    }
  };
};

withField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.boolean,
};

export default withField;
