import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withField from './withField';

const Input = ({
  placeholder,
  name,
  type,
  onChange,
  value,
  hasError,
  disabled,
  className: customClasses,
  ...props
}) => {
  const classes = classNames(
    'appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline',
    {
      'is-invalid': hasError,
      'bg-grey-lighter': disabled,
    },
    customClasses
  );
  return (
    <textarea
      className={classes}
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
      {...props}
    />
  );
};
Input.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'password']),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};
Input.defaultProps = {
  type: 'text',
  hasError: false,
  disabled: false,
};

export { Input as _Input };
export default withField(Input);
